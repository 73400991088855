<template>
  <div style="margin-top: 40px">
    <v-banner
        sticky
        class="text-h6"
        :style="{background: this.$vuetify.theme.themes[theme].appbarbackground, top: heightOfAppBar, borderBottom: '1px solid '+ bannerBottomColor}"
        single-line
    >
      {{ title }}
    </v-banner>
    <v-list
        two-line
        flat
    >
      <v-list-item-group
          v-model="model"
      >
        <template v-for="(item, i) in Playlist">
          <v-list-item
              :key="item.id + item.data.sitsub.toString()"
              :ref="'videopanel' + item.id+ item.data.sitsub.toString()"
              v-ripple=false
          >
            <template>
              <v-expand-transition>
                <v-btn
                    v-show="item.showPanel"
                    absolute
                    right
                    top
                    fab
                    text
                    style="top: 5px"
                    @click="closeVideoPanel(item);"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-expand-transition>
              <v-list-item-content
              >

                <v-list-item-title
                    class="font-weight-medium body-2"
                    style="white-space: initial; max-width: 85%;"
                    @click="openVideoPanel(item);"
                >
                  {{ getHeaderOfBox(item) }}

                </v-list-item-title>
                <p v-html="item.data.desc" class="body-2 mb-3" @click="openVideoPanel(item);"></p>
                <v-expand-transition
                    :key="item.id+item.data.sitsub">
                  <VideoPanel
                      v-show="item.showPanel"
                      :key="item.id+item.data.sitsub.toString()"
                      :title="getObsCat(item)"
                      :description="item.description"
                      :video_src=item.src
                      :idb="item.id"
                  >
                  </VideoPanel>
                </v-expand-transition>
                <v-row align="center" v-show="item.showPanel && item.data.dt === 4 && isNotHex">
                  <v-col cols="6">
                    <v-select
                        :items="getSubSitItems(item)"
                        v-model="input"
                        item-text="name"
                        item-value="id"
                        label="Situationsunterklasse"
                        hint="Wählen Sie bitte die Situation zu ihrer Prüfung"
                        persistent-hint
                        return-object
                        single-line
                        @change="setSitSub(item)"
                    ></v-select>
                  </v-col>
                </v-row>
                <p v-show="item.showPanel" @click="goToObscat(item.data.dt, item.data.sdt, item.data.sitsub)"
                   class="body-2 font-italic v-size--small">Zu den Allgemeinen Anforderungen ({{ getDtName(item) }}
                  {{ getSdtName(item).length > 0 ? ">" : "" }} {{ getSdtName(item) }} {{ getSubSitName(item).length > 0 ? ">" : "" }} {{ getSubSitName(item) }})
                  <v-icon>
                    mdi-arrow-top-right
                  </v-icon>
                </p>

              </v-list-item-content>
              <v-list-item-action
                  v-show="!item.showPanel"
              >
                <v-progress-circular
                    :rotate="-90"
                    :size="50"
                    :width="3"
                    :value=item.timeValue
                    color="blue"
                    @click="openVideoPanel(item);"
                >
                  <v-icon
                  >mdi-play-circle-outline
                  </v-icon>
                </v-progress-circular>
              </v-list-item-action>

            </template>
          </v-list-item>
          <v-divider
              :key="i"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import Vue from "vue";
import VideoPanel from "@/components/panels/VideoPanel.vue";


export default Vue.extend({
  name: "Playlist",
  components: {
    VideoPanel,
  },
  data: () => ({
    model: [],
    heightOfAppBar: {},
    VideoPanelP: {
      id: Number,
      data: {},
      title: '',
      src: '',
      video_poster: '',
      description: '',
      showPanel: false,
      timeValue: Number
    },
    input: 1,
  }),
  props: {
    Playlist: {},
    title: String,
    bannerBottomColor: String,

  },
  computed: {
    theme() {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    isNotHex(){
      return !this.$store.state.ishex;
    }
  },
  mounted() {
    this.getHeightOfAppBarAndSetBannerStickyTop();
  },
  methods: {
    setSitSub(item) {
      item.data.sitsub = this.input.id;
      this.getTranscriptFromVideoP(item);
    },
    openVideoPanel(item) {
      if (item.showPanel) {
        this.closeVideoPanel(item);
        return;
      }
      item.showPanel = true;
      this.$nextTick(() => {
        this.$vuetify.goTo(this.$refs["videopanel" + item.id + item.data.sitsub.toString()][0].$el, {offset: 48})
      });
    },
    closeVideoPanel(item) {
      item.showPanel = false;
      try {
        this.$refs['videopanel'+item.id+ item.data.sitsub.toString()][0].$children[1].$children[0].$refs['plyr'+item.id].$el.pause();
      }catch (e){return}
    },
    getHeightOfAppBarAndSetBannerStickyTop() {
      let appBar = document.getElementsByClassName("appBarHeight")[0];
      const ro = new ResizeObserver(()/*entries*/ => {
        this.heightOfAppBar = appBar.clientHeight + 'px';
      });
      ro.observe(appBar);
      this.heightOfAppBar = appBar.clientHeight + 'px';

    },
    getHeaderOfBox(item) {
      let sdt = this.getSdtName(item)
      let sitsub = this.getSubSitName(item)
      //return this.getObsCat(item) + " " + (sdt.length !== 0 ? "bei " + sdt : sitsub.length !== 0 ? " mit " + this.getSubSitName(item) : "") + " (" + this.getDtName(item) + ")"
      return this.getObsCat(item) + " bei " + (sdt.length !== 0 ? sdt : this.getDtName(item)) +  (sitsub.length !== 0 ? " (" + this.getSubSitName(item) +")" : "")
    },
    getDtName(item) {
      return this.$store.state.selecteddrivingtaskcatalog[item.data.dt - 1].name
    },
    getSdtName(item) {
      const dt = this.$store.state.selecteddrivingtaskcatalog[item.data.dt - 1]
      if (dt.subdrivingtasks.length === 0)
        return "";
      else
        return dt.subdrivingtasks[item.data.sdt - 1].name
    },
    getSubSitName(item) {
      const dt = this.$store.state.selecteddrivingtaskcatalog[item.data.dt - 1]
      if (dt.subdrivingtasks.length === 0)
        return "";
      if (!dt.subdrivingtasks[item.data.sdt - 1].situationsubclasses)
        return "";
      if (item.data.dt === 4 && item.data.sitsub === 0) //for ?p=
        return "";
      return dt.subdrivingtasks[item.data.sdt - 1]
          .situationsubclasses[item.data.sitsub - 1].name
    },
    getSubSitItems(item) {
      const dt = this.$store.state.selecteddrivingtaskcatalog[item.data.dt - 1]
      if (dt.subdrivingtasks.length === 0)
        return null;
      if (!dt.subdrivingtasks[item.data.sdt - 1].situationsubclasses)
        return null;
      return dt.subdrivingtasks[item.data.sdt - 1]
          .situationsubclasses
    },
    getObsCat(item) {
      return this.$store.state.observationcats[item.data.obscat - 1].name2
    },
    getVideoName(item) {
      let dt = item.data.dt;
      let sdt = item.data.sdt === 0 ? 1 : item.data.sdt;
      let obscat = item.data.obscat;
      let sitsub = item.data.sitsub === 0 ? "" : "-" + item.data.sitsub;
      let drivclass = item.data.drivinglicence; //is drivingclass necessary
      return dt + "." + sdt + "." + 2 + "." + obscat + "" + sitsub + "-" + drivclass
    },
    goToObscat(dt, sdt, sitsub) {
      if(dt ===4 && sitsub===0)sitsub=1;
      if(sitsub!==0)
        this.$router.push({name: 'ObservationCatGrid', params: {dtid: dt.toString(), sdtid: sdt.toString(), sitsubid: sitsub.toString()}})
      else
        this.$router.push({name: 'ObservationCatGrid', params: {dtid: dt.toString(), sdtid: sdt.toString()}})
    },
    checkError: function (response) {
      if (response.status >= 200 && response.status <= 299) {
        return response.json();
      } else {
        throw Error(response.statusText);
      }
    }
    ,
    getVideoNameP(item) {
      let sitsubarray = ["I", "II", "III", "IV"]
      let dt = item.data.dt;
      let sdt = item.data.sdt === 0 ? 1 : item.data.sdt;
      let obscat = item.data.obscat;
      let sitsub = item.data.sitsub === 0 ? "" : "-" + sitsubarray[item.data.sitsub - 1];
      let name = dt + "." + sdt + "." + 2 + "." + obscat + "" + sitsub /*+ "-" + drivclass*/
      return this.getVideoNameForDrivingClass(name, item);
    },
    getTranscriptFromVideoP(item) {
      item.src = this.getVideoNameP(item) + '.mp4';
      let path = this.getVideoNameP(item) + ".json";
      fetch("/json/transcript/" + path)
          .then(this.checkError)
          .then(data => {
            item.description = data.transcript;
          }).catch((error) => {
        console.log(error)
      });
    },
    getVideoNameForDrivingClass(name, item) {
      const json_fe = this.$store.state.fe_video_map;
      let vname = '';
      for (let key in json_fe) {
        if (key.includes(name)) {
          vname = name;
          if (json_fe[key].split(',').includes(item.data.drivinglicence)) {
            return key.toString();
          }
        }
      }
      return vname;
    }
  }
});
</script>

<style scoped>

</style>